import { useTranslation } from 'react-i18next';

function Loading() {

    const { t } = useTranslation();

    return (
        <div className="loading">
            <p style={{textAlign: 'center', padding: '100px 0'}}>{t('loading.text')}</p>
        </div>
    )
}

export default Loading;