import { useTranslation } from "react-i18next"
import reportingPreview1 from '../../assets/images/reporting-preview-1.png';
import reportingPreview2 from '../../assets/images/reporting-preview-2.png';
import pictureHome2 from '../../assets/images/picture-home-2.jpg';
import logo_uwe0 from '../../assets/images/logos/logo_uwe.jpg';
import logo_agoria1 from '../../assets/images/logos/logo_agoria.jpg';
import logo_wallonie_entreprendre2 from '../../assets/images/logos/logo_wallonie_entreprendre.png';
import logo_awex3 from '../../assets/images/logos/logo_awex.png';
import logo_bep4 from '../../assets/images/logos/logo_bep.png';
import logo_cap_innove5 from '../../assets/images/logos/logo_cap_innove.png';
import logo_cci_lvn6 from '../../assets/images/logos/logo_cci_lvn.png';
import logo_cei_louvain7 from '../../assets/images/logos/logo_cei_louvain.png';
import logo_charleroi_entreprendre8 from '../../assets/images/logos/logo_charleroi_entreprendre.png';
import logo_eklo9 from '../../assets/images/logos/logo_eklo.png';
import logo_embuild10 from '../../assets/images/logos/logo_embuild.png';
import logo_entreprendre_wapi11 from '../../assets/images/logos/logo_entreprendre_wapi.png';
import logo_greenwin12 from '../../assets/images/logos/logo_greenwin.png';
import logo_hec_digital_lab13 from '../../assets/images/logos/logo_hec_digital_lab.png';
import logo_idea14 from '../../assets/images/logos/logo_idea.png';
import logo_idelux15 from '../../assets/images/logos/logo_idelux.png';
import logo_infopole16 from '../../assets/images/logos/logo_infopole.png';
import logo_la_maison_de_l_entreprise17 from '../../assets/images/logos/logo_la_maison_de_l_entreprise.png';
import logo_mic18 from '../../assets/images/logos/logo_mic.png';
import logo_ucm19 from '../../assets/images/logos/logo_ucm.png';
import logo_wfg_ostbelgien20 from '../../assets/images/logos/logo_wfg_ostbelgien.png';
import logo_spw_eer21 from '../../assets/images/logos/logo_spw_eer.png';
import LogoPartner from "../../components/LogoPartner/LogoPartner";
import pictureHome3 from '../../assets/images/picture-home-3.jpg';
import { useLocation, useNavigate } from "react-router-dom";
import './Home.css';

const logos = [
  { src: logo_uwe0, alt: 'UWE', link: 'https://www.uwe.be' },
  { src: logo_agoria1, alt: 'Agoria', link: 'https://www.agoria.be' },
  { src: logo_wallonie_entreprendre2, alt: 'Wallonie entreprendre', link: 'https://www.wallonie-entreprendre.be/' },
  { src: logo_awex3, alt: 'AWEX', link: 'https://www.awex.be/' },
  { src: logo_bep4, alt: 'BEP', link: 'https://www.bep.be/' },
  { src: logo_cap_innove5, alt: 'Cap Innove', link: 'https://www.capinnove.be/' },
  { src: logo_cci_lvn6, alt: 'CCI LVN', link: 'https://www.ccilvn.be/' },
  { src: logo_cei_louvain7, alt: 'CEI Louvain', link: 'https://www.facebook.com/CEEILouvain/' },
  { src: logo_charleroi_entreprendre8, alt: 'Charleroi Entreprendre', link: 'https://charleroi-entreprendre.be/' },
  { src: logo_eklo9, alt: 'EKLO', link: 'https://eklo.be/' },
  { src: logo_embuild10, alt: 'Embuild', link: 'https://wallonie.embuild.be/fr' },
  { src: logo_entreprendre_wapi11, alt: 'Entreprendre Wapi', link: 'https://www.entreprendrewapi.be/' },
  { src: logo_greenwin12, alt: 'Greenwin', link: 'https://www.greenwin.be/fr/' },
  { src: logo_hec_digital_lab13, alt: 'HEC Digital Lab', link: 'https://www.digitallab.be/fr/' },
  { src: logo_idea14, alt: 'IDEA', link: 'https://www.idea.be/' },
  { src: logo_idelux15, alt: 'Idelux', link: 'https://www.idelux.be/' },
  { src: logo_infopole16, alt: 'Infopole', link: 'https://clusters.wallonie.be/infopole/fr' },
  { src: logo_la_maison_de_l_entreprise17, alt: "La Maison de l'Entreprise", link: 'https://www.lme.be/' },
  { src: logo_mic18, alt: 'MIC', link: 'https://www.mic-belgique.be/' },
  { src: logo_ucm19, alt: 'UCM', link: 'https://www.ucm.be/' },
  { src: logo_wfg_ostbelgien20, alt: 'WFG Ostbelgien', link: 'https://www.wfg.be/fr/home-2/' },
  { src: logo_spw_eer21, alt: 'SPW EER', link: 'https://www.wallonie.be/fr/acteurs-et-institutions/wallonie/service-public-de-wallonie/spw-economie-emploi-recherche' }
]

const version = "1.0.2"

export default function Home() {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  function goToSurvey() {
    navigate('/survey', { state: { from: location.pathname } });
  }

  return (
    <div className="home">
      <p>version {version}</p>
      <div className="intro">
        <div className="block-text-image block-text-image--loud">
          <div className="block-text-image__main">
            <div className="block-text-image__head">
              <h1 className="block-text-image__title">{t('home.intro.title')}</h1>
            </div>
            <div className="block-text-image__content">
              <div className="block-text-image__text">
                <p>{t('home.intro.p0')}</p>
                <p>{t('home.intro.p1')}</p>
                <ul>
                  <li>{t('home.intro.li0')}</li>
                  <li>{t('home.intro.li1')}</li>
                  <li>{t('home.intro.li2')}</li>
                </ul>
                <div className="block-text-image__button">
                  <button onClick={goToSurvey} className="btn btn-fullwidth btn-gradient">{t('home.intro.button')}</button>
                </div>
              </div>
              <div className="block-text-image__image">
                <div className="block-text-image__picture-1">
                  <img alt={t('home.intro.alt1')} srcSet={`${reportingPreview1} 623w, ${reportingPreview1} 1246w`}
                    sizes="(max-width: 480px) 240px, 800px"
                    src={reportingPreview1} />
                </div>
                <div className="block-text-image__picture-2">
                  <img alt={t('home.intro.alt1')} srcSet={`${reportingPreview2} 453w, ${reportingPreview2} 906w`}
                    sizes="(max-width: 480px) 240px,800px"
                    src={reportingPreview2} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="block-cta">
        <div className="block-cta__main">
          <div className="block-cta__content">
            <div className="block-cta__content-text">
              <h2>{t('home.cat.title')}</h2>
              <p>{t('home.cat.p0')}</p>
            </div>
            <div className="block-cta__content-button">
              <button onClick={() => window.open(t('home.cat.buttonHref'), "_blank")} className="btn btn-fullwidth btn-gradient">{t('home.cat.button')}</button>
            </div>
          </div>
        </div>
      </section>
      <section id={`${t('home.why.anchor')}`}>
        <div className="paragraph">
          <div className="paragraph__main">
            <div className="paragraph__list">
              <div className="paragraph__list-item">
                <div className="paragraph-item">
                  <div className="paragraph-item__content">
                    <h2 className="paragraph-item__title">{t('home.why.title')}</h2>
                    <div className="paragraph-item__text">
                      <p><strong>{t('home.why.p0strong')}</strong> {t('home.why.p0')}</p>
                      <p>{t('home.why.p1')}</p>
                    </div>
                  </div>
                  <div className="player-container">
                    <iframe src="https://www.youtube.com/embed/8zi3a7AFnIc" title="DigiScore by Digital Wallonia" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                  </div>
                </div>
              </div>
              <div className="paragraph__list-item">
                <div className="paragraph-item">
                  <div className="paragraph-item__content">
                    <div className="paragraph-item__text">
                      <p><strong>{t('home.why.p2strong')}</strong></p>
                      <p>{t('home.why.p3')}</p>
                      <ul>
                        <li>{t('home.why.li0')}</li>
                        <li>{t('home.why.li1')}</li>
                      </ul>
                      <p>{t('home.why.p4')}</p>
                    </div>
                    <div className="paragraph-item__button">
                      <button onClick={goToSurvey} className="btn btn-gradient">{t('home.why.button')}</button>
                    </div>
                  </div>
                  <div className="paragraph-item__image">
                    <div className="paragraph-item__picture">
                      <img srcSet={`${pictureHome2} 623w, ${pictureHome2} 1246w`}
                        sizes="(max-width: 480px) 240px, 800px"
                        src={pictureHome2}
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id={`${t('home.how.anchor')}`}>
        <div className="text-sidebar">
          <div className="text-sidebar__main">
            <div className="text-sidebar__head">
              <h2 className="text-sidebard__title">{t('home.how.title')}</h2>
            </div>
            <div className="text-sidebar__content">
              <div className="text-sidebar__left">
                <div className="text-sidebar__text">
                  <p><strong>{t('home.how.p0strong')}</strong> {t('home.how.p0')}</p>
                  <p><strong>{t('home.how.p1strong')}</strong> {t('home.how.p1')}</p>
                  <p>{t('home.how.p2')}</p>
                  <p>{t('home.how.p3')}</p>
                  <ul>
                    <li><p>{t('home.how.li0')}</p></li>
                    <li><p>{t('home.how.li1')}</p></li>
                  </ul>
                </div>
                <div className="paragraph-item__button">
                  <button onClick={goToSurvey} className="btn btn-gradient">{t('home.how.button')}</button>
                </div>
              </div>
              <div className="text-sidebar__side">
                <div className="side-block">
                  <div className="side-block__picture">
                    <img
                      srcSet={`${pictureHome2} 623w, ${pictureHome2} 1246w`}
                      sizes="(max-width: 480px) 240px, 800px"
                      src={pictureHome2}
                      alt=""
                    />
                  </div>
                  <div className="side-block__content">
                    <h3 className="side-block__title">{t('home.how.side-block')}</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id={`${t('home.companySurvey.anchor')}`}>
        <div className="text-blocks">
          <div className="text-blocks__main">
            <div className="text-blocks__head">
              <h2 className="text-blocks__title">{t('home.companySurvey.title')}</h2>
              <div className="text-blocks__text">
                <p><strong>{t('home.companySurvey.p0strong')}</strong> {t('home.companySurvey.p0')}</p>
                <p><strong>{t('home.companySurvey.p1strong')}</strong></p>
              </div>
            </div>
            <div className="text-blocks__list">
              <div className="text-blocks__list-item">
                <h3 className="text-blocks__list-title">{t('home.companySurvey.item0-title')}</h3>
                <div className="text-blocks__list-text">
                  <p>{t('home.companySurvey.item0-content')}</p>
                </div>
              </div>
              <div className="text-blocks__list-item">
                <h3 className="text-blocks__list-title">{t('home.companySurvey.item1-title')}</h3>
                <div className="text-blocks__list-text">
                  <p>{t('home.companySurvey.item1-content')}</p>
                </div>
              </div>
              <div className="text-blocks__list-item">
                <h3 className="text-blocks__list-title">{t('home.companySurvey.item2-title')}</h3>
                <div className="text-blocks__list-text">
                  <p>{t('home.companySurvey.item2-content')}</p>
                </div>
              </div>
              <div className="text-blocks__list-item">
                <h3 className="text-blocks__list-title">{t('home.companySurvey.item3-title')}</h3>
                <div className="text-blocks__list-text">
                  <p>{t('home.companySurvey.item3-content')}</p>
                </div>
              </div>
            </div>
            <div className="text-blocks__bottom">
              <p><strong>{t('home.companySurvey.p2strong')}</strong> {t('home.companySurvey.p2')}</p>
              <p>{t('home.companySurvey.p3')}</p>
              <p>{t('home.companySurvey.p4')} <a href={t('home.companySurvey.p4a')} target="_blank" rel="noreferrer">{t('home.companySurvey.p4a')}</a></p>
            </div>
          </div>
        </div>
      </section>
      <section className="block-partners" id={`${t('home.partners.anchor')}`}>
        <div className="block-partners__main">
          <h2 className="block-partners__title">{t('home.partners.title')}</h2>
          <div className="block-partners__list">
            {logos.map((item, i) => {
              return <LogoPartner key={i} src={item.src} alt={item.alt} link={item.link} />
            })}
          </div>
        </div>
      </section>
      <section className="block-text-image" id={`${t('home.useofdata.anchor')}`}>
        <div className="paragraph">
          <div className="paragraph__main">
            <div className="paragraph__list">
              <div className="paragraph__list-item">
                <div className="paragraph-item">
                  <div className="paragraph-item__content">
                    <h2 className="paragraph-item__title">{t('home.useofdata.title')}</h2>
                    <div className="paragraph-item__text">
                      <p>{t('home.useofdata.p0')}</p>
                      <p>{t('home.useofdata.p1')}</p>
                      <p>{t('home.useofdata.p2')}</p>
                      <p>{t('home.useofdata.p3')}</p>
                      <a href={t('home.useofdata.p3a')} target="_blank" rel="noreferrer"><p>{t('home.useofdata.p3a')}</p></a>
                    </div>
                  </div>
                  <div className="paragraph-item__image">
                    <div className="paragraph-item__picture">
                      <img src={pictureHome3} alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="block-faq" id={`${t('home.faq.anchor')}`}>
        <div className="block-faq__main">
          <h2 className="block-faq__title">{t('home.faq.title')}</h2>
          <div className="block-faq__list">
            <div className="block-faq__list-item">
              <div className="block-faq-item">
                <p><strong>{t('home.faq.question0')}</strong></p>
                <p>{t('home.faq.answer0')}</p>
              </div>
            </div>
            <div className="block-faq__list-item">
              <div className="block-faq-item">
                <p><strong>{t('home.faq.question1')}</strong></p>
                <p>{t('home.faq.answer1')}</p>
              </div>
            </div>
            <div className="block-faq__list-item">
              <div className="block-faq-item">
                <p><strong>{t('home.faq.question2')}</strong></p>
                <p>{t('home.faq.answer2')}</p>
              </div>
            </div>
            <div className="block-faq__list-item">
              <div className="block-faq-item">
                <p><strong>{t('home.faq.question3')}</strong></p>
                <p>{t('home.faq.answer3')}</p>
              </div>
            </div>
            <div className="block-faq__list-item">
              <div className="block-faq-item">
                <p><strong>{t('home.faq.question4')}</strong></p>
                <p>{t('home.faq.answer4')}</p>
              </div>
            </div>
            <div className="block-faq__list-item">
              <div className="block-faq-item">
                <p><strong>{t('home.faq.question5')}</strong></p>
                <p>{t('home.faq.answer5')}</p>
              </div>
            </div>
            <div className="block-faq__list-item">
              <div className="block-faq-item">
                <p><strong>{t('home.faq.question6')}</strong></p>
                <p>{t('home.faq.answer6')}</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}