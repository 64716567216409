import AuthWrapper from "./components/AuthWrapper/AuthWrapper";
import { TokenContext } from "./components/AuthWrapper/AuthWrapper";
import { useContext } from "react";
import { Home, LegalInformation, SurveyPage, SurveyResultPage, SurveyStartPage, RessourcesPage, Report } from "./pages";
import { useAuth0 } from "@auth0/auth0-react";
import { useLocation } from 'react-router-dom';
import ErrorPage from "./pages/Error/ErrorPage";

function ProtectedRoute({ PageComponent }) {
  const token = useContext(TokenContext);
  const location = useLocation();
  const { user } = useAuth0();

  // Report page is not accessible for anyone except the scribe (and the admin). The scribe is a user (bot) with the role DIGISCORE_SCRIBE, its function is the access the report page and print it to pdf.
  if (location.pathname.startsWith('/report') && (!user["https://digitalwallonia.be/role"].includes("DIGISCORE_SCRIBE") && !user["https://digitalwallonia.be/role"].includes("DIGISCORE_ADMIN"))) {
    return <div>No access</div>
  }

  return token && <PageComponent token={token} />
}

const router = [
  //Public routes
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/error",
    element: <ErrorPage />
  },
  {
    path: "/legalinformation",
    element: <LegalInformation />,
  },

  //Protected routes
  {
    path: '/survey',
    element: (
      <AuthWrapper returnTo={'/survey'}>
        <ProtectedRoute PageComponent={SurveyPage}/> 
      </AuthWrapper>
    )
  },
  {
    path: '/survey/start',
    element: (
      <AuthWrapper returnTo={'/survey/start'}>
        <ProtectedRoute PageComponent={SurveyStartPage}/> 
      </AuthWrapper>
    )
  },
  {
    path: '/survey/result/:params',
    element: (
      <AuthWrapper returnTo={'/survey/result'}>
        <ProtectedRoute PageComponent={SurveyResultPage}/> 
      </AuthWrapper>
    )
  },
  {
    path: '/ressources',
    element: (
      <AuthWrapper returnTo={'/ressources'}>
        <ProtectedRoute PageComponent={RessourcesPage}/> 
      </AuthWrapper>
    )
  },
  {
    path: '/report/:sessionId',
    element: (
      <AuthWrapper returnTo={'/report'}>
        <ProtectedRoute PageComponent={Report}/> 
      </AuthWrapper>
    )
  }
];

export default router;
